import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import '../Cards.css';
import CardItem from '../CardItem.js';

export default function Projects() {
  return (
    <>
      <h1 className='projects'>Projects</h1>

      <hr />

      <div className='project-container'>
      <div className='cards__container'>
        <div className='cards__wrapper'>
        <ul className='cards__items'>
            <CardItem
              src={require('../../assets/images/thing2.jpg')}
              text='Thing - ASL capable robotic hand'
              label='Prototyping, CAD (SolidWorks), Programming (C)'
              buttontext="Github"
              linkto='https://github.com/Evie-Boug/ASL_Robotic_Hand'
            />
            <CardItem
              src={require('../../assets/images/dog.jpg')}
              text='Microservo quadruped'
              label='CAD (SolidWorks), Electrical, Controls'
              // buttontext="Google Drive"
              // linkto='https://drive.google.com/drive/folders/1XsuXKLy6s9vfbsJ7-EmtY2MNgoM2mKEV?usp=drive_link'
            />
          </ul>

          <ul className='cards__items'>
            <CardItem
              src={require('../../assets/images/melman.jpg')}
              text='Melman - FRC 2023 Robot'
              label='Prototyping, CAD (SolidWorks), Electrical'
              buttontext="Google Drive"
              linkto='https://drive.google.com/drive/folders/1XsuXKLy6s9vfbsJ7-EmtY2MNgoM2mKEV?usp=drive_link'
            />
            <CardItem
              src={require('../../assets/images/pocketnotion.jpeg')}
              text='Pocket Notion - Chrome extension for productivity'
              label='HTML, CSS, JS'
              buttontext="Devpost"
              linkto='https://devpost.com/software/pocket-notion'
            />
          </ul>

          <ul className='cards__items'>
            <CardItem
              src={require('../../assets/images/joqe.jpeg')}
              text='Joqe - Chrome extension generating jokes'
              label='HTML, CSS, JS'
              buttontext="Add to chrome!"
              linkto='https://chrome.google.com/webstore/detail/joqe/ioloamkjhccknkmjlpcjkibdcnggkpne?hl=en'
            />
            <CardItem
              src={require('../../assets/images/demobot.png')}
              text='Demo bot made for the 2017 FRC game'
              label='Java, Control systems'
            />
          </ul>

          <ul className='cards__items'>
            <CardItem
              src={require('../../assets/images/gregory.png')}
              text='Discord bot that can roll a dice or flip a coin on command'
              label='Python, Flask'
              buttontext="Invite me to discord!"
              linkto='https://discord.com/oauth2/authorize?client_id=808740073054404672&permissions=8&scope=bot%20applications.commands'
            />
            <CardItem
              src={require('../../assets/images/essgrads.png')}
              text='Website made for the 2021 Graduating Class at Erindale'
              label='HTML, CSS, JS'
            />
          </ul>
        </div>
      </div>
      </div>


      <Footer />
    </>
  );
}