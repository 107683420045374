import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import { SpotifyNowPlaying } from './SpotifyNowPlaying';

function Footer() {

  return (
    <div className='footer-container'>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='social-icons'>

            <Link
              className='social-icon-link linkedin'
              to='https://www.linkedin.com/in/rubieluo/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>

            <Link
              className='social-icon-link github'
              to='https://github.com/rubieluo'
              target='_blank'
              aria-label='Github'
            >
              <i className='fab fa-github' />
            </Link>

            {/* <Link
              className='social-icon-link devpost'
              to='https://devpost.com/rubieluo'
              target='_blank'
              aria-label='Devpost'
            >
              <svg stroke="currentColor" fill="currentColor" strokeWidth="0" role="img" viewBox="0 0 24 24" className="inline mr-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <title></title>
                <path d="M6.002 1.61L0 12.004 6.002 22.39h11.996L24 12.004 17.998 1.61zm1.593 4.084h3.947c3.605 0 6.276 1.695 6.276 6.31 0 4.436-3.21 6.302-6.456 6.302H7.595zm2.517 2.449v7.714h1.241c2.646 0 3.862-1.55 3.862-3.861.009-2.569-1.096-3.853-3.767-3.853Z"></path>
              </svg>
            </Link> */}

            <Link
              className='social-icon-link mail'
              to='mailto:rubie.luo@uwaterloo.ca'
              target='_blank'
              aria-label='Mail'
            >
              <i className='fa-solid fa-envelope' />
            </Link>
          </div>

          <small className='website-rights'>&copy; 2024 Rubie Luo</small>
          
          <SpotifyNowPlaying />

        </div>
      </section>
    </div>
  );
}

export default Footer;