import '../../App.css';
import Footer from '../Footer';
import { Link } from 'react-router-dom';

export default function contact() {
  return (
    <>
      <h1 className='contact'>Contact Me!</h1>
      <img className='gif dog' src={require('../../assets/images/dog.gif')} alt="dog!" />

      <hr />

      <div className='container-left'>
        <p> <strong> Let's talk! I'm always happy to chat, feel free to say hey :) </strong></p>

        <Link
          className='contact-item'
          to='mailto:rubie.luo@uwaterloo.ca'
          target='_blank'
          aria-label='Mail'
        >
          <i className='fa-solid fa-envelope' /> Email: rubie.luo@uwaterloo.ca
        </Link>

        <Link
          className='contact-item'
          to='https://www.linkedin.com/in/rubieluo/'
          target='_blank'
          aria-label='LinkedIn'
        >
          <i className='fab fa-linkedin' /> LinkedIn: linkedin.com/in/rubieluo
        </Link>

        <Link
          className='contact-item'
          to='https://github.com/rubieluo'
          target='_blank'
          aria-label='Github'
        >
          <i className='fab fa-github' /> Github: github.com/rubieluo
        </Link>

        {/* <Link
          className='contact-item'
          to='https://devpost.com/rubieluo'
          target='_blank'
          aria-label='Devpost'
        >
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" role="img" viewBox="0 0 24 24" className="inline mr-1" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <title></title>
            <path d="M6.002 1.61L0 12.004 6.002 22.39h11.996L24 12.004 17.998 1.61zm1.593 4.084h3.947c3.605 0 6.276 1.695 6.276 6.31 0 4.436-3.21 6.302-6.456 6.302H7.595zm2.517 2.449v7.714h1.241c2.646 0 3.862-1.55 3.862-3.861.009-2.569-1.096-3.853-3.767-3.853Z"></path>
          </svg> Devpost: devpost.com/rubieluo
        </Link> */}

        {/* <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input type="email" className="form-control" aria-describedby="emailHelp" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea className="form-control" rows="5"></textarea>
          </div>
          <button type="submit" className="btn btn-primary">Submit</button>
        </form> */}


      </div>
      <Footer />
    </>
  );
}