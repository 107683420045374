import { useEffect, useState } from "react";
import getNowPlayingItem from "./SpotifyAPI";
import SpotifyLogo from "./SpotifyLogo";
import './Spotify.css';

export const SpotifyNowPlaying = (props) => {
    const [loading, setLoading] = useState(true);
    const [result, setResult] = useState({});
    useEffect(() => {
        Promise.all([
            getNowPlayingItem(
                props.client_id,
                props.client_secret,
                props.refresh_token
            ),
        ]).then((results) => {
            if (!result) {
                return 0;
            }
            setResult(results[0]);
            setLoading(false);
        });
    }
    // , [] //UNCOMMENT WHOLE LINE TO MAKE IT ONLY UPDATE ON REFRESH
);

    const song = result.title + ' by ' + result.artist;
    return (
        <div>
            {(!loading && !result) ? (
                // <div className="spotify-container">
                //     <div className='spotlogo'><SpotifyLogo /></div>
                //     <span>Currently offline</span>
                // </div>
                <div className="spotify-container">
                    <div className='spotlogo'>
                            <SpotifyLogo />
                    </div>
                    <div>
                        <p>Listening to</p>
                        <a className='song-name' href='https://open.spotify.com/track/71GvlH0VdeClloLIkHrAVu' target="_blank" rel="noreferrer">Diamonds on the Soles of Her ...</a>
                    </div>
                </div>
            ) : (
                <div className="spotify-container">
                    <div className='spotlogo'>
                        <SpotifyLogo />
                    </div>
                    <div>
                        <p>Listening to</p>
                        {song.length > 30 ?
                            <a className='song-name' href={result.songUrl} target="_blank" rel="noreferrer">{song.substring(0, 28)} ...</a>
                            : <a className='song-name' href={result.songUrl} target="_blank" rel="noreferrer">{song}</a>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};
