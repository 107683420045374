import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection() {

  return (
    <div className='hero-container'>
      {window.innerWidth > 960 && <video src={require('../assets/videos/video-2.mp4')} autoPlay loop muted />}
      <p>Hey, I'm</p>
      <h1>Rubie Luo</h1>
      <small className='description'>a mechatronics student at the University of Waterloo with a passion for learning</small>

      {/* <div className='hero-btns'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        >
          My Resume (when i figure out hwo to lnk it)
        </Button>
      </div> */}
    </div>
  );
}

export default HeroSection;