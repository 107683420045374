import React from 'react';
import { Link } from 'react-router-dom';
import './Button.css';
import './Cards.css';

function CardItem(props) {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  return (
    <>
      <li className='cards__item'>
        <Link className='cards__item__link' to={props.path} onClick={props.onClick}>
          <figure className='cards__item__pic-wrap' data-category={props.label}>
            <img
              className='cards__item__img'
              alt='Project'
              src={props.src}
            />
            {/* {props.seemore && <h3 className="first-txt"> {props.seemore} </h3>} */}
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{props.text}</h5>
            {props.linkto && <button className='btns btn--outline--invert btn--medium' role="link" onClick={() => openInNewTab(props.linkto)} > {props.buttontext} </button>}
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;