import React from 'react';
import '../../App.css';
import Footer from '../Footer';

export default function About() {
  return (
    <>
      <h1 className='about'>About me!</h1>
      <img className='gif frog' src={require('../../assets/images/frog.GIF')} alt="frog!" />

      <hr />

      <div className='container letter-space'>
        <p> <br /> Hey! I am a mechatronics student at the University of Waterloo who is passionate about learning and trying new things! <br /> <br />
        
        I have experience with countless types and varieties of programming languages; I've done courses in C, C++, Python, and Java, and 
          I've taught myself C#, PHP, HTML, CSS, JavaScript & JQuery, and React. I'm a quick learner and an ambitious scholar. I enjoy spending my free time trying new things that challenge me. <br /> <br />
        
        Apart from the programming side of things, my main interests lie within robotics. I love rapid prototyping, CAD, electrical, and controls. I'm proficient in SolidWorks and Altium to complement the software experience I have.
           <br /> <br />

        I want to continue building on skills and dialing in on my interests. My hope is to work on diverse projects to exercise varying skills, finding what I like most in the process.

        
       </p>
       <img className='headshot' src={require('../../assets/images/wrench.jpg')} alt="me!" />
      </div>
      

      

      <Footer />
    </>
  );
}