import React from 'react';
import './Cards.css';
import CardItem from './CardItem.js';
import { Link } from 'react-router-dom';

function Cards() {
  const closeMobileMenu = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  return (
    <div className='cards'>
      <h1>Check out some of my projects!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              id="thing"
              src={require('../assets/images/thing.jpg')}
              text='Thing - ASL capable robotic hand'
              label='Prototyping, CAD (SolidWorks), Programming (C)'
              path='/projects'
              seemore='See More'
              onClick={closeMobileMenu}
            />
            <CardItem
              src={require('../assets/images/dog2.jpg')}
              text='Microservo quadruped'
              label='CAD (SolidWorks), Electrical, Controls'
              path='/projects'
              seemore='See More'
              onClick={closeMobileMenu}
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={require('../assets/images/melman.jpg')}
              text='Melman - FRC 2023 Robot'
              label='Prototyping, CAD (SolidWorks), Electrical'
              path='/projects'
              seemore='See More'
              onClick={closeMobileMenu}
            />
            <CardItem
              src={require('../assets/images/pocketnotion.jpeg')}
              text='Pocket Notion - Chrome extension for productivity'
              label='HTML, CSS, JS'
              path='/projects'
              onClick={closeMobileMenu}
            />
            <CardItem
              src={require('../assets/images/demobot.png')}
              text='Demo bot made for the 2017 FRC game'
              label='Java, Control systems'
              path='/projects'
              onClick={closeMobileMenu}
            />
          </ul>
        </div>
      </div>
      <Link
        className='seeprojects'
        to='/projects'
        onClick={closeMobileMenu}
      >
        Learn More
      </Link>
    </div>
  );
}

export default Cards;